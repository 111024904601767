import { ref } from "vue";
// Quote data

const quotes = ref(null);
const storedQuotes = ref(null);
export function useQuotes() {
  const order = ["LEVEL_TERM", "INCOME_REPLACEMENT", "EXPENSE_COVERAGE"];
  const sort = (quotes) => {
    return [...quotes].sort((a, b) => {
      const aType = a.quote?.benefitType || null;
      const bType = b.quote?.benefitType || null;
      // If aType or bType is not in the order array, set them as 'Other'.
      const aRank = order.includes(aType) ? order.indexOf(aType) : 0;
      const bRank = order.includes(bType) ? order.indexOf(bType) : 0;
      return aRank - bRank;
    });
  };

  return {
    quotes,
    storedQuotes,
    sort,
  };
}
