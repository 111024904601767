import { camelize, isArray } from "@dayforward/common";
import { toRaw } from "vue";

import { questionKeys } from "../constants";
import { useSchema } from "./schema";

const EMPTY = "";
const { SCHEMA } = useSchema();
export function useAnswers() {
  function _filterKey(key, includedKeys) {
    return includedKeys ? includedKeys?.includes(key) : true;
  }

  function _transformObject(key, map) {
    let values = toRaw(map[key]);
    let question = SCHEMA.value[camelize(key)];
    if (!isArray(values))
      if (values === undefined || values === EMPTY) {
        // Allow empty answer for required questions (exp: SSN)
        if (question && question.allowsNone && question.required && question.active) values = EMPTY;
        else values = null;
      } else {
        values = [values.toString()];
      }
    else if (key === questionKeys.FAMILY_HISTORY) return { questionKey: key, familyHistory: values };
    // If value array return values that arn't empty strings
    if (values && values.length > 1) values = values.filter((v) => v !== "");

    return { questionKey: key, values };
  }

  function _mapToObject(keys, map, includedKeys = undefined) {
    return keys.filter((key) => _filterKey(key, includedKeys)).map((key) => _transformObject(key, map));
  }

  function parseAnswers(map, includedKeys = undefined) {
    return _mapToObject(Object.keys(map), map, includedKeys);
  }

  /**
   * Used to parse data from a form object and use a optional map to compare data changes
   * @param {Object} data { FIRST_NAME: "First", LAST_NAME: "Last"}
   * @param {Object} map { FIRST_NAME: "", LAST_NAME: ""}
   * @returns {Array} [{ questionKey: key, values: data[key]}, ....]
   */
  function parseData(data, map = null) {
    const set = new Set();
    for (const key in data) {
      if (map && data[key] !== map[key]) {
        set.add(_transformObject(key, data));
      }
      if (!map) {
        data[key];
        set.add(_transformObject(key, data));
      }
    }
    return [...set];
  }

  function mapAnswersFromObject(collection, data) {
    for (const property in collection) {
      data[property] = collection[property];
    }
    return data;
  }

  function mapAnswersFromArray(collection, data) {
    return collection.reduce((a, item) => {
      if (!item.multipleChoice) data[item.key] = item?.answers[0];
      if (item.multipleChoice && item.answers.length) data[item.key] = item.answers;
      return data;
    }, data);
  }

  function mapAnswers(collection, data) {
    if (isArray(collection)) return mapAnswersFromArray(collection, data);
    else return mapAnswersFromObject(collection, data);
  }

  return {
    parseAnswers,
    parseData,
    mapAnswers,
  };
}
