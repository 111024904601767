import { gql } from "@apollo/client/core";

export const interviewAnswersQuery = gql`
  query interviewAnswers($id: String!, $keys: [String!]!, $categories: [String!], $concerns: [String!]) {
    interviewAnswers(id: $id, where: { keys: $keys, concerns: $concerns }) {
      questionKey
      values
    }
    questions(interviewID: $id, where: { keys: $keys, defaultCategories: $categories, concerns: $concerns }) {
      key
      label
      text
      required
      allowsNone
      active
      answer {
        values
        familyHistory {
          diagnoses
          severity
          relationship
        }
      }
      answers
      valueType
      multipleChoice
      concerns
      options {
        text
        value
        selected
        hint
      }
    }
  }
`;

export const getEmailRedirectQuery = gql`
  query getEmailRedirect($email: String!) {
    getEmailRedirect(email: $email) {
      redirectStatus
    }
  }
`;
export const agentQuery = gql`
  query agent($id: String!) {
    agent(id: $id) {
      id
      agencyId
      accountId
      agentRole
      agentEmail
    }
  }
`;

export const accountQuery = gql`
  query account($token: String!) {
    account(token: $token) {
      id
      agencyId
      interviews {
        applicant {
          firstName
          lastName
          emailAddress
          phoneNumbers {
            number
            type
          }
        }
        id
        firstName
        product {
          variant
          vendor
        }
        questions(where: { key: "ACCEPTED_NOTICES" }) {
          key
          answers
        }
        status
        policy {
          id
          number
          status
          startDate
          endDate
          semiMonthlyBenefit
          coveredIncome
          monthlyPremium
          termPeriod
          holder {
            firstName
            lastName
            emailAddress
            accountID
            dateOfBirth
            gender
            address {
              line1
              line2
              city
              state
              postalCode
              countryCode
            }
          }
          beneficiaries {
            relationship
            trust {
              name
              formationDate
            }
            person {
              firstName
              lastName
              emailAddress
              accountID
              dateOfBirth
              gender
              address {
                line1
                line2
                city
                state
                postalCode
                countryCode
              }
            }
          }
        }
      }
    }
  }
`;

export const getInterviewMarkerByNameQuery = gql`
  query getInterviewMarkerByName($id: String!, $name: String!) {
    getInterviewMarkerByName(id: $id, name: $name) {
      metadata {
        key
        value
      }
      name
    }
  }
`;

export const occupationsWithKeys = gql`
  query OccupationsWithKeys($keys: [String!]!) {
    occupations(where: { keys: $keys }) {
      key
      name
    }
  }
`;

export const occupationsQuery = gql`
  query occupations($term: String!, $skip: Int = 0, $take: Int = 5) {
    occupations(where: { match: $term }, skip: $skip, take: $take) {
      key
      name
    }
  }
`;

export const quoteQuery = gql`
  fragment quoteData on Quote {
    benefitType
    benefitAmount
    benefitLimit {
      minimum
      maximum
    }
    benefitAmountOptions
    hasPremium
    premium
    premiumLimit {
      minimum
      maximum
    }
    premiumAbsoluteLimit {
      minimum
      maximum
    }
    premiumPer1kIncome
    premiumPer1YearExtension
    rate
    employment {
      code
      description
    }
    annualIncome
    annualBenefit
    annualBenefitLimit {
      minimum
      maximum
    }
    termRecommended
    hasOverride
    locked
    termEndDate
    termReason {
      code
      description
      untilChildAge
      untilInsuredAge
    }
    termLimit {
      minimum
      maximum
    }
    youngestChildAgeLimit {
      minimum
      maximum
    }
    hasChild
    youngestChildAge
    insuredAge
    insuredAgeLimit {
      minimum
      maximum
    }
    isEstimate
    childExtensionAvailable
    childExtensionMaxAge
    childExtensionMaxYearsRemaining
    subscriptionFee
    incomeRiderMaxAnnualIncome
    incomeRiderMaxTotalBenefit
  }
  query quote($interviewID: String!, $override: QuoteOverride) {
    quote(interviewID: $interviewID, override: $override) {
      success
      reason
      quote {
        ...quoteData
      }
      # quoteLow {
      #   ...quoteData
      # }
      # quoteMedium {
      #   ...quoteData
      # }
      # quoteHigh {
      #   ...quoteData
      # }
    }
  }
`;

// This query is a paired down quote query for the revised Quote Page (DFT-999)
// - can always add more fields back in as needed
export const sliderQuoteQuery = gql`
  query quote($interviewID: String!, $override: QuoteOverride) {
    quote(interviewID: $interviewID, override: $override) {
      success
      reason
      quote {
        benefitType
        benefitAmount
        benefitLimit {
          minimum
          maximum
        }
        hasPremium
        childExtensionAvailable
        premium
        premiumPer1kIncome
        premiumPer1YearExtension
        premiumLimit {
          minimum
          maximum
        }
        premiumAbsoluteLimit {
          minimum
          maximum
        }
        annualIncome
        annualBenefit
        annualBenefitLimit {
          minimum
          maximum
        }
        termRecommended
        hasOverride
        locked
        termEndDate
        termReason {
          code
          description
          untilChildAge
          untilInsuredAge
        }
        termLimit {
          minimum
          maximum
        }
        youngestChildAgeLimit {
          minimum
          maximum
        }
        childExtensionMaxAge
        childExtensionMaxYearsRemaining
        hasChild
        youngestChildAge
        insuredAge
        insuredAgeLimit {
          minimum
          maximum
        }
        isEstimate
        incomeRiderMaxAnnualIncome
        incomeRiderMaxTotalBenefit
      }
    }
  }
`;

export const diagnosesQuery = gql`
  query Diagnoses($term: String, $skip: Int = 0, $take: Int = 5) {
    diagnoses(where: { match: $term }, skip: $skip, take: $take) {
      code
      description
    }
  }
`;

export const diagnosesWithCodes = gql`
  query Diagnoses($codes: [String!]) {
    diagnoses(where: { codes: $codes }) {
      code
      description
      abbreviatedDescription
      category {
        code
        title
      }
    }
  }
`;

export const medicationsQuery = gql`
  query MedicationsWithNames($match: String!) {
    medications(where: { match: $match }) {
      key
      activeName
    }
  }
`;

export const medicationsWithKeysQuery = gql`
  query MedicationsWithKeys($keys: [String!]!) {
    medications(where: { keys: $keys }) {
      key
      activeName
    }
  }
`;

export const medicationsWithNamesQuery = gql`
  query MedicationsWithNames($names: [String!]!) {
    medications(where: { names: $names }) {
      key
      activeName
    }
  }
`;

export const addressQuery = gql`
  query address($query: String!, $preferState: State) {
    address(query: $query, preferState: $preferState) {
      line1
      line2
      city
      state
      postalCode
    }
  }
`;

export const interviewQuery = gql`
  query interview($id: String!) {
    interview(id: $id) {
      status
      quote {
        isEstimate
        locked
      }
      interactions {
        type
      }
    }
  }
`;

export const interviewStatusQuery = gql`
  query interview($id: String!) {
    interview(id: $id) {
      status
    }
  }
`;

export const policyDownloadURLQuery = gql`
  query policyDownloadURL($id: String!) {
    policyDownloadURL(id: $id)
  }
`;

export const creditCardQuery = gql`
  query {
    creditCards {
      id
      brand
      last4
      expMonth
      expYear
    }
  }
`;
