import { STORAGE_KEYS, useSessionStorage } from "@composables";

import { useAuthStore } from "@/store/auth.store";
import { useUserStore } from "@/store/user.store";

import { interviewStatus, routeNames } from "../constants";

const { get } = useSessionStorage();

export const interviewRoutes = [
  {
    path: "/or/sorry",
    name: routeNames.OFF_RAMP_INELIGIBLE,
    component: () => import("../pages/v3/InterviewIneligible.vue"),
  },
  {
    path: "/or/waitlist",
    name: routeNames.OFF_RAMP_WAITLIST,
    component: () => import("../pages/v3/InterviewWaitinglist.vue"),
  },
  {
    path: "/welcome-back",
    name: routeNames.INTERVIEW_WELCOME_BACK,
    component: () => import("../pages/interview/InterviewWelcomeBack.vue"),
  },
  {
    path: "/interview",
    name: routeNames.INTERVIEW,
    redirect: { name: routeNames.INTERVIEW_INITIAL_STEP },
    component: () => import(/* webpackChunkName: "interview" */ "../pages/interview/Interview.vue"),
    beforeEnter: async (to, from, next) => {
      const authStore = useAuthStore();
      // Check if user can access interview routes
      // const lead = get(STORAGE_KEYS.DF_LEAD);
      // if (!lead && !authStore.authenticated && to.name !== routeNames.INTERVIEW_INTRO) {
      //   next({ name: routeNames.QUOTE });
      //   return;
      // }
      // END

      // Check authenticated session
      if (authStore.authenticated) {
        const { redirect } = await authStore.sessionEffect(); // redirect:Boolean
        if (redirect) {
          next({ name: routeNames.AUTH_SIGN_IN });
          return;
        }
      }
      // END

      next();
    },
    children: [
      {
        path: "start",
        name: routeNames.INTERVIEW_INITIAL_STEP,
        component: () => import(/* webpackChunkName: "i-initial-step" */ "../pages/interview/InterviewInitialStep.vue"),
      },
      // This is route is for HR redirects to the interview start which allows access to start an interview without quick quote data
      {
        path: "intro",
        name: routeNames.INTERVIEW_INTRO,
        component: () => import(/* webpackChunkName: "i-initial-step" */ "../pages/interview/InterviewIntro.vue"),
      },
      {
        path: "basic-info",
        name: routeNames.INTERVIEW_BASIC_INFO,
        component: () => import(/* webpackChunkName: "i-basic-into" */ "../pages/interview/InterviewBasicInfo.vue"),
      },
      {
        path: "location-info",
        name: routeNames.INTERVIEW_LOCATION,
        component: () => import(/* webpackChunkName: "i-location" */ "../pages/interview/InterviewLocation.vue"),
      },
      {
        path: "income",
        name: routeNames.INTERVIEW_INCOME,
        component: () => import(/* webpackChunkName: "i-income" */ "../pages/interview/InterviewFinances.vue"),
      },
      {
        path: "driving",
        name: routeNames.INTERVIEW_DRIVING,
        component: () => import(/* webpackChunkName: "i-driving" */ "../pages/interview/InterviewDriving.vue"),
      },
      {
        path: "lifestyle",
        name: routeNames.INTERVIEW_HABITS,
        component: () => import(/* webpackChunkName: "i-habits" */ "../pages/interview/InterviewHabits.vue"),
      },
      {
        path: "health",
        name: routeNames.INTERVIEW_HEALTH,
        component: () => import(/* webpackChunkName: "i-health" */ "../pages/interview/InterviewHealth.vue"),
      },

      {
        path: "health-conditions",
        name: routeNames.INTERVIEW_HEALTH_CONDITIONS,
        component: () =>
          import(/* webpackChunkName: "i-health-conditons" */ "../pages/interview/InterviewHealthConditions.vue"),
      },
      {
        path: "health-conditions-diagnosis",
        name: routeNames.INTERVIEW_HEALTH_CONDITIONS_DIAGNOSIS,
        component: () =>
          import(
            /* webpackChunkName: "i-health-diagnosis" */ "../pages/interview/InterviewHealthConditionsDiagnosis.vue"
          ),
      },
      {
        path: "health-conditions-details/:diagnosisIndex",
        name: routeNames.INTERVIEW_HEALTH_CONDITIONS_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "i-health-diagnosis-details" */ "../pages/interview/InterviewHealthConditionsDetails.vue"
          ),
      },
      {
        path: "beneficiaries",
        name: routeNames.INTERVIEW_BENEFICIARIES,
        component: () =>
          import(/* webpackChunkName: "i-beneficiaries" */ "../pages/interview/InterviewBeneficiaries.vue"),
      },
      {
        path: "insurance-history",
        name: routeNames.INTERVIEW_INSURANCE_HISTORY,
        component: () =>
          import(/* webpackChunkName: "i-insturance-history" */ "../pages/interview/InterviewInsuranceHistory.vue"),
      },
      {
        path: ":type",
        name: routeNames.INTERVIEW_QUOTE,
        component: () => import(/* webpackChunkName: "i-quote" */ "../pages/interview/InterviewQuote.vue"),
        //props: true,
        beforeEnter: (to, from, next) => {
          const userStore = useUserStore();
          if (userStore.interview?.status === undefined) {
            next({ name: routeNames.INTERVIEW });
            return;
          }
          if (userStore.interview?.status === interviewStatus.INELIGIBLE) {
            next({ name: routeNames.OFF_RAMP_INELIGIBLE, replace: true });
            return;
          }
          to.params.from = from;
          next();
        },
      },
      {
        path: "checkout",
        name: routeNames.INTERVIEW_PAYMENT,
        component: () => import(/* webpackChunkName: "i-payment" */ "../pages/interview/InterviewPayment.vue"),
      },
      {
        path: "sign",
        name: routeNames.INTERVIEW_E_SIGN,
        component: () => import(/* webpackChunkName: "i-sign" */ "../pages/interview/InterviewEsign.vue"),
      },
      {
        path: "thanks",
        name: routeNames.INTERVIEW_THANKS,
        component: () => import(/* webpackChunkName: "i-thanks" */ "../pages/interview/InterviewThanks.vue"),
      },
      {
        path: "more-info-needed",
        name: routeNames.MANUAL_UNDERWRITTING,
        component: () => import(/* webpackChunkName: "i-more-info" */ "../pages/v3/InterviewReview.vue"),
      },
      {
        path: "review",
        name: routeNames.INTERVIEW_REVIEW,
        component: () => import(/* webpackChunkName: "i-review" */ "../pages/v3/InterviewReview.vue"),
      },
    ],
  },
];
