<template>
  <TheHeader :to="props.to">
    <slot></slot>
  </TheHeader>
</template>

<script setup>
import TheHeader from "@dayforward/common/src/components/TheHeader.vue";

import { routeNames } from "../constants";
const props = defineProps({
  to: {
    type: Object,
    default: () => ({ name: routeNames.HOME }),
  },
});
</script>

<style lang="scss" module></style>
