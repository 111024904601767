import { defineStore } from "pinia";

import { useInterviewStore } from "../interview.store";

export const useQuoteStore = defineStore("quote", {
  state: () => ({
    $quote: undefined,
    overrideType: undefined,
    selectedAnnualBenefit: null,
    selectedPremium: null,
    selectedTermLimit: null,
    selected: null,
  }),
  getters: {
    termCode() {
      return this.$quote.termReason.code;
    },
    quote: (state) => {
      return state.$quote;
    },
    estimate: (state) => {
      return state.$quote ? state.$quote.isEstimate : false;
    },
  },
  actions: {
    setQuote(quote) {
      this.$quote = quote;
    },
    syncQuoteFromInterviewStore() {
      const interviewStore = useInterviewStore();
      this.$quote = interviewStore?.quote;
    },
    setQuoteSelections(overrideType, overrideValue, termLength, annualBenefit) {
      this.overrideType = overrideType;
      switch (overrideType) {
        case "annualBenefit":
          this.selectedAnnualBenefit = overrideValue;
          this.selectedPremium = null;
          this.selectedTermLimit = parseInt(termLength);
          break;
        case "monthlyPremium":
          this.selectedAnnualBenefit = null;
          this.selectedPremium = overrideValue;
          this.selectedTermLimit = null;
          break;
        case "termLength":
          this.selectedAnnualBenefit = annualBenefit;
          this.selectedPremium = null;
          this.selectedTermLimit = overrideValue;
          break;
      }
    },
  },
});
