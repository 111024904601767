<template>
  <a :class="$style.skip" href="#main">Skip to main content</a>
  <header :class="[$style.header, $attrs.class]">
    <div :class="[$style.container]">
      <div :class="[$style['group__left']]">
        <router-link :class="$style.logo" :to="props.to" active-class="" exact-active-class="">
          <slot name="logo">
            <BaseLogo size="22" />
          </slot>
        </router-link>
      </div>
      <div :class="[$style['group__right']]">
        <slot></slot>
      </div>
    </div>
  </header>
</template>

<script setup>
import BaseLogo from "./BaseLogo.vue";

const props = defineProps({
  to: {
    type: Object,
    default: () => ({ name: "home" }),
  },
});
</script>
<style lang="scss" module>
.skip {
  position: absolute;
  left: 50%;
  z-index: 9999;
  padding: 4px;
  color: #fff;
  font-size: 14px;
  background: var(--base-color-secondary, #1a113c);
  transform: translate(-50%, -100%);
  transition: transform 230ms;
  &:focus {
    transform: translate(-50%, 0);
  }
}
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  width: 100%;
  background-color: var(--oat, #f2ede0);
  min-height: 64px;
  transition: box-shadow 0.25s ease-in 0.1s;
  //border: 1px solid red;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 2em;
  margin-left: 2.5em;
}

.group__left,
.group__right {
  display: flex;
  align-items: center;
}

.logo {
  display: inline-flex;
  outline: none;
  &:hover {
    border-bottom-color: transparent;
  }
  &:focus-visible {
    box-shadow: 0 0 0 4px rgb(26 17 60 / 20%);
  }
}
</style>
