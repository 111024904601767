import { ref } from "vue";

import { useQuestions } from "./useQuestions";
import { useRequired } from "./useRequired";

const sections = ref([]);
const filteredSections = ref([]); // Contains id of filtered sections
const section = ref(null);
const toggled = ref([]);

function generateId(inputString) {
  // Remove any non-alphanumeric characters except dashes
  const cleanString = inputString.replace(/[^a-zA-Z0-9-]/g, "");

  // Generate a unique ID based on the cleaned string
  let id = "";
  for (let i = 0; i < cleanString.length; i++) {
    const char = cleanString.charAt(i);
    const charCode = char.charCodeAt(0);
    id += charCode.toString(16);
  }

  // Pad the ID with zeros to ensure it has at least 12 characters
  const paddedId = id.padStart(12, "0");

  return paddedId;
}

export function useSections() {
  const { required } = useRequired();
  const { complete: questionComplete, completed: questionsComplete } = useQuestions();

  const order = [
    ["_DIAGNOSES"],
    ["_OTHER"],
    ["_CURRENTLY_TREATED"],
    ["_DIAGNOSED_WHEN", "_END_DATE"],
    ["_TREATMENT"],
    ["_WHEN_SURGERY"],
    ["_SURGERY_EXPLICATION"],
    ["_TREATMENT_MEDICATIONS"],
    ["_OTHER_EXPLICATION"],
    ["_SYMPTOM_WHEN"],
    ["_A1C_AVERAGE"],
    ["_A1C_READING_WHEN"],
    ["_COMPLICATIONS"],
    ["_RELAPSED"],
  ];

  function orderIndexEffect(arg) {
    for (let i = 0; i < order.length; i++) {
      const item = order[i];
      if (Array.isArray(item)) {
        for (let j = 0; j < item.length; j++) {
          if (arg.key.endsWith(item[j])) return i;
        }
      } else if (arg.key.endsWith(item)) return i;
    }
    return Infinity;
  }

  const sortEffect = (a, b) => {
    const orderIndexA = orderIndexEffect(a);
    const orderIndexB = orderIndexEffect(b);
    if (orderIndexA !== orderIndexB) return orderIndexA - orderIndexB;
    else {
      // Within the same order index, maintain the specific order as defined in the order array
      const subOrderA = order[orderIndexA];
      if (!subOrderA) return;
      const subOrderIndexA = subOrderA.findIndex((item) => a.key.endsWith(item));
      const subOrderB = order[orderIndexB];
      const subOrderIndexB = subOrderB.findIndex((item) => b.key.endsWith(item));
      return subOrderIndexA - subOrderIndexB;
    }
  };

  const reduceEffect = (a, c) => {
    const orderIndex = orderIndexEffect(c);
    if (!a[orderIndex]) a[orderIndex] = [c];
    else a[orderIndex].push(c);
    return a;
  };

  // Section is complete
  function complete(section) {
    const isComplete = section.items.flatMap(({ questions }) => questions.filter(required)).every(questionComplete);
    return isComplete;
  }

  // All sections completed
  function completed(sections) {
    const isComplete = sections.every(complete);
    return isComplete;
  }

  const filterEffect = (v) => v !== undefined;
  //@TODO Needs refactored to make more readable
  function transformSections(funnel, questions) {
    const [$sections, $groupings, , $overrides] = funnel;
    // Create each section for standard questions
    const groupings = $groupings.filter((g) => g.length); // remove quote and signin grouping

    sections.value = $sections
      .split(" ")
      .filter((s) => !["quote", "sign"].includes(s))
      .reduce((c, s, i) => {
        const section = {
          id: generateId(s),
          title: s.split("-").join(" "),
          complete: false,
          items: groupings[i].reduce((a, g, j) => {
            const $q = questions
              .filter(({ key }) => g.includes(key))
              .sort((a, b) => {
                const indexA = g.indexOf(a.key);
                const indexB = g.indexOf(b.key);
                return indexA - indexB;
              });
            if (!$q.length) return a;
            let label = $overrides[i] && $overrides[i][j] ? $overrides[i][j] : $q.at(0).label;
            if (label === "State of issue") label = "Driver's license number";
            a.push({
              id: generateId($q.at(0).key),
              slug: $q.at(0).key,
              label,
              complete: questionsComplete($q),
              keys: $q.map(({ key }) => key),
              questions: [...$q],
            });
            return a;
          }, []),
        };
        section.complete = complete(section, required); // Is section complete
        c.push(section);
        return c;
      }, []);

    // Health reflexives setup
    const diagnoseSection = (answer, question) => {
      const data = new Proxy(
        {
          id: generateId(answer),
          title: `${answer
            .replace(/_DIAGNOSES$/, "")
            .replace(/_/g, " ")
            .toLowerCase()} disorder`,
          complete: false,
          items: [
            {
              id: generateId(question.key),
              slug: question.key,
              label: question.label,
              complete: questionsComplete([question]),
              keys: [question.key],
              questions: [question],
            },
          ],
        },
        {},
      );
      data.complete = complete(data, required); // Is section complete
      return data;
    };
    const { answers } = questions.find(({ key }) => key === "HEALTH_CONDITIONS");
    // If we have answers build each section for health reflexives
    if (answers?.length && answers?.at(0) !== "") {
      const healthFollowUpSections = [];
      const substanceAbuseSection = [];
      answers.forEach((answer) => {
        // Find the diagnoses for the current answer health condition question for health followup
        const findDiagnoses = ({ defaultCategory, concerns }) =>
          defaultCategory === "HEALTH_FOLLOWUP" && concerns.includes(answer);
        const diagnoses = questions.find(findDiagnoses);
        if (diagnoses) {
          const { answers: a, options } = diagnoses;
          // Add the follup diagnose sections
          healthFollowUpSections.push(diagnoseSection(answer, diagnoses));
          // Remove _END_DATE question if CURRENTLY_TREATED is true
          const currentlyTreated = questions.find(({ key }) => key.endsWith("_CURRENTLY_TREATED"));
          const hideEndDate =
            currentlyTreated && currentlyTreated.answer.values !== null
              ? currentlyTreated.answer.values[0] === "true"
              : false;
          const removeEndDate = (j) => j.filter(({ key }) => !key.endsWith("_END_DATE"));
          // Add diagnosis answer detail sections
          a.reduce((c, i) => {
            // q = an array of arrays organized by the order array
            const q = questions
              .filter(({ concerns }) => concerns.includes(i))
              .sort(sortEffect)
              .reduce(reduceEffect, [])
              .filter(filterEffect);

            const { text } = options.find(({ value }) => value === i);
            const data = new Proxy(
              {
                id: generateId(i),
                title: `${text} details`,
                complete: false,
                items: q.reduce((a, j) => {
                  const jFiltered = !hideEndDate ? j : removeEndDate(j);
                  const label = !jFiltered[0].key.endsWith("_DIAGNOSED_WHEN")
                    ? jFiltered[0].label
                    : "Diagnosis or treatment date(s):";
                  a.push({
                    id: generateId(jFiltered[0].key),
                    slug: jFiltered[0].key,
                    label: label,
                    complete: questionsComplete(jFiltered),
                    keys: jFiltered.map(({ key }) => key),
                    questions: [...jFiltered],
                  });
                  return a;
                }, []),
              },
              {},
            );
            data.complete = complete(data); // Is section complete
            c.push(data);
            return c;
          }, healthFollowUpSections);
        } else {
          // Add substance abuse sections
          // q = an array of arrays organized by the order array
          const q = questions
            .filter(({ key }) => key.startsWith("SUBSTANCE_ABUSE"))
            .sort(sortEffect)
            .reduce(reduceEffect, [])
            .filter(filterEffect);
          if (q.length) {
            const data = new Proxy(
              {
                id: generateId("SUBSTANCE_ABUSE"),
                title: `Substance abuse details`,
                complete: false,
                items: q.reduce((a, j) => {
                  const label = !j[0].key.endsWith("_DIAGNOSED_WHEN") ? j[0].label : "Diagnosis or treatment date(s):";
                  a.push({
                    id: generateId(j[0].key),
                    slug: j[0].key,
                    label: label,
                    complete: questionsComplete(j),
                    keys: j.map(({ key }) => key),
                    questions: j,
                  });
                  return a;
                }, []),
              },
              {},
            );
            data.complete = complete(data);
            substanceAbuseSection.push(data);
          }
        }
      });
      // Insert new health reflexive sections
      const index = sections.value.indexOf(sections.value.find(({ title }) => title === "health conditions"));
      sections.value = [
        ...sections.value.slice(0, index + 1),
        ...healthFollowUpSections,
        ...(substanceAbuseSection.length ? substanceAbuseSection : []),
        ...sections.value.slice(index + 1),
      ];
    }
    return sections.value;
  }

  return {
    section,
    sections,
    filteredSections,
    transformSections,
    toggled,
    complete,
    completed,
  };
}
